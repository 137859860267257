<template>
  <div>
    <AppBreadcrumbCustom :breadcrumb="breadcrumb" />

    <b-card>
      <div>
        <validation-observer ref="provider">
          <b-form>
            <b-row>
              <b-col md="4">
                <b-form-group label="Payment Method Id" label-for="paymentMethodId">
                  <validation-provider #default="{ errors }" name="paymentMethodId" rules="required">
                    <b-form-input
                      v-model.number="paymentMethodId"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Payment Method Id"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group label="Coin id" label-for="coinId">
                  <validation-provider #default="{ errors }" name="coinId" rules="required">
                    <b-form-select
                      v-model.number="coinId"
                      type="number"
                      value-field="id"
                      text-field="fullName"
                      :options="coinOptions"
                      :state="errors.length > 0 ? false : null"
                      placeholder="coinId"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group label="Fee" label-for="fee">
                  <validation-provider #default="{ errors }" name="fee">
                    <b-form-input
                      v-model.number="fee"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                      placeholder="fee"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Action Buttons -->
            <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click.prevent="save">
             {{ this.coinId ? 'Edit' : 'Create' }}
            </b-button>
          </b-form>
        </validation-observer>
      </div>
    </b-card>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BForm,
  BCard,
  BFormCheckbox,
  BBreadcrumb,
  BBreadcrumbLink,
  BBreadcrumbItem,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'

// eslint-disable-next-line
import { required } from '@validations'
import AppTable from '@/components/AppTable.vue'
import AppBreadcrumbCustom from '@/components/AppBreadcrumbCustom.vue'
import vSelect from 'vue-select'
export default {
  name: 'PaymentMethodsCreate',
  components: {
    vSelect,
    AppBreadcrumbCustom,
    AppTable,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BFormCheckbox,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BForm,
    BCard,
    BBreadcrumb,
    BBreadcrumbLink,
    BBreadcrumbItem,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      paymentMethodId: null,
      coinId: null,
      fee: 0,
      paymentMethodFees: null,
    }
  },
  computed: {
    ...mapGetters({
      currency: 'cryptoCurrency/cryptoCurrency',
    }),

    coinOptions() {
      return this.currency?.value?.map(item => {
        const { emoji = '', name = '', network = '' } = item
        const coin = `${emoji || ''} ${name} ${network ? '/ ' + network : ''}`

        return { ...item, fullName: coin }
      })
    },
    breadcrumb() {
      return [
        {
          text: 'Payment Methods',
          active: true,
          to: '/payment-methods',
        },
        {
          text: 'Edit Payment Methods',
          active: true,
          to: `/payment-methods/${this.paymentMethodId}`,
        },
        {
          text: 'Fee Edit',
        },
      ]
    },
  },
  mounted() {
    this.initState()
  },
  methods: {
    ...mapActions({
      fetchPaymentMethodById: 'paymentMethods/fetchPaymentMethodById',
      createPaymentMethodFee: 'paymentMethods/createPaymentMethodFee',
      updatePaymentMethodFee: 'paymentMethods/updatePaymentMethodFee',
      fetchCryptoCurrency: 'cryptoCurrency/fetchCryptoCurrency',
    }),

    initState() {
      this.getCurrency({ page: 1, limit: 9 })
      this.registerValidator(this.$refs.provider)
      
      if (this.$route.params.paymentMethodId) {
        // create page
        this.paymentMethodId = parseInt(this.$route.params.paymentMethodId)
       
      } else {
        // edit page
        if (!this.$route.params.feeId || !this.$route.params.id) return

        this.paymentMethodId = parseInt(this.$route.params.id)
        this.coinId = this.$route.params.feeId
        this.getPaymentMethodById(this.paymentMethodId)
      }
    },

    create() {
      this.waitRequest(() => {
        return this.createPaymentMethodFee({
          paymentMethodId: this.paymentMethodId,
          coinId: this.coinId,
          fee: this.fee,
        })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Fee is added',
                icon: 'PlusCircleIcon',
                variant: 'info',
              },
            })
            this.$router.go(-1)
          })
          .catch(this.checkErrors)
      })
    },

    update() {
      this.waitRequest(() => {
        return this.updatePaymentMethodFee({
          paymentMethodId: this.paymentMethodId,
          coinId: this.coinId,
          fee: this.fee,
        })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Fee is updated',
                icon: 'PlusCircleIcon',
                variant: 'info',
              },
            })
            this.$router.go(-1)
          })
          .catch(this.checkErrors)
      })
    },

    getCurrency(query = {}) {
      return this.waitRequest(() => {
        return this.fetchCryptoCurrency(query).catch(this.checkErrorsAlert)
      })
    },

    async save() {
      const isValid = await this.checkIsValidForm()

      if (this.requestInProgress || !isValid) return

      if (this.paymentMethodId) return this.update()

      this.create()
    },

    getPaymentMethodById(paymentMethodId) {
      this.fetchPaymentMethodById(paymentMethodId)
          .then(response => {
            this.paymentMethodFees = response.data?.paymentMethodFees
            this.fee = this.paymentMethodFees.find(item => item.coin.id === this.coinId).fee
          })
          .catch(this.checkErrors)
    },
  },
}
</script>

