<template>
  <b-row>
    <b-col class="content-header-left mb-2" cols="12" md="9">
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ title || $route.meta.pageTitle }}
          </h2>

          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon icon="HomeIcon" size="16" class="align-text-top" />
              </b-breadcrumb-item>

              <b-breadcrumb-item v-for="item in breadcrumb" :key="item.text" :active="!item.active" :to="item.to">
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import Vue from 'vue'
import Component from 'vue-class-component'
import { BBreadcrumb, BBreadcrumbItem, BCol, BRow } from 'bootstrap-vue'

export default {
  name: 'AppBreadcrumbCustom',
  components: { BBreadcrumb, BBreadcrumbItem, BCol, BRow },
  props: {
    breadcrumb: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
  },
}
</script>
